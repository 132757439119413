<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder">
          <div class="card-body ">
            <filter-regional v-model:value="regionals" />
          </div>
          <div class="card-body mt-2">
             <filter-kabupaten-kota  v-model:value="kabupaten" v-model:area="areas" />
          </div>
          <div class="card-body mt-2 ">
            <a-input-search
              v-model:value="value"
              placeholder="Cari ..."
              style="width: 300px"
              @search="onSearch"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder">
          <div class="card-body ">
              <filter-province v-model:value="province" v-model:regional="regionals" />
          </div>
          <div class="card-body mt-2">
            <filter-distributor v-model:value="distributors"/>
          </div>
        </div>
        <div class="card-placeholder">
          <div class="card-body ">
             <a-button type="primary" title="cari" @click="search">
              <i class="fa fa-search" aria-hidden="true"></i>
            </a-button>
            <a-button
             class="ml-2"
              type="primary"
              stylea="float: right; margin-right: -80%;"
              title="Download Xls"
              @click="fetchXlsx"
            >
          <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        </a-button>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder">
          <div class="card-body">
             <filter-area  v-model:value="areas" v-model:province="province" />
          </div>
          <div class="card-body mt-2">
             <a-select label-in-value v-model:value="value" style="width: 300px" @change="handleChange" placeholder="Pilih Toko">
            <a-select-option value="toko_081000155504">Toko 081000155504</a-select-option>
            <a-select-option value="toko_081000155503">Toko 081000155503</a-select-option>
            <a-select-option value="toko_081000155502">Toko 081000155504</a-select-option>
            <a-select-option value="toko_081000155501">toko 081000155504</a-select-option>
             <a-select-option value="toko_test_ajjajaja_edit">Toko Test Ajjajaja Edit</a-select-option>
             <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
            </a-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder">
          <div class="card-body mt-2">
                <a-select label-in-value v-model:value="value" style="width: 300px" @change="handleChange" placeholder="Urutkan dari besar ke kecil">
            <a-select-option value=""></a-select-option>
            </a-select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
          <div class="table-responsive">
      <a-table :columns="columns"
              :data-source="data"
              :row-selection="rowSelection"
              size="small"
              row-key="id"
              :pagination="{
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                total: meta.totalCount,
                pageSize: meta.perPage,
                current: meta.currentPage,
              }"
              :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
              bordered
              @change="handleTableChange"
              :loading="isFetching"
            >
            <template
          #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div class="custom-filter-dropdown">
            <a-input
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm)"
              >Search</a-button
            >
            <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
              >Reset</a-button
            >
          </div> </template
        ><template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #filterIcon="{ text: filtered }">
          <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #customer="{ text }">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
          </template>
        </template>
        <template #progress="{ text: bar }">
          <div class="progress">
            <div
              :class="['progress-bar', bar.color]"
              :style="{ width: bar.value + '%' }"
              role="progressbar"
            ></div>
          </div>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #total="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #tax="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #shipping="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #status="{ text }">
          <span
            :class="[
              text === 'Aktif'
                ? 'font-size-12 badge badge-primary'
                : 'font-size-12 badge badge-default',
            ]"
            >{{ text }}</span
          >
        </template>
        <template #action>
          <span>
            <a-tooltip title="Lihat Pengguna">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <i class="fe fe-eye" />
              </a>
            </a-tooltip>
            <a-tooltip title="Ubah Pengguna">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Detail Regional dan Area">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <small>
                  <i class="fe fe-globe" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </a-table>
    </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adata from './data.json'
import { ref, watch, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'
// import MModal from './modal'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'
const acolumns = [
  {
    title: 'ID Toko',
    dataIndex: 'id',
    slots: { customRender: 'id' },
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: 'Nama Toko',
    dataIndex: 'nama_toko',
    slots: { customRender: 'nama_toko' },
  },
  {
    title: 'Total Poin',
    dataIndex: 'total_poin',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
// function renderVNode(_, { attrs: { vnode } }) {
// return vnode
// }
export default {
 components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
  },
  setup() {
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    const current1 = ref(1)
    data.value = adata.slice(1, 5)
    columns.value = acolumns

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    return {
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
       perPage,
      totalCount,
      pageCount,
      page,
      meta,
    }
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>

